import React from 'react';
import Markdown from '../modules/Markdown';
import notes from '../../RELEASE_NOTES.md';

const ReleaseNotes = () => (
  <div className="width-full px-3">
    <h2>Release Notes</h2>
    <Markdown className="width-full">{notes}</Markdown>
  </div>
);

export default ReleaseNotes;
