import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const ClickOutsideHandler = ({
  onClickOutside,
  children,
  className,
  style
}) => {
  const containerRef = useRef();
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, { capture: true });
    return () => {
      document.removeEventListener('click', handleClickOutside, {
        capture: true
      });
    };
  }, []);

  const handleClickOutside = e => {
    if (!containerRef.current.contains(e.target)) {
      onClickOutside(e);
    }
  };

  return (
    <div ref={containerRef} className={classnames(className)} style={style}>
      {children}
    </div>
  );
};

ClickOutsideHandler.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
  onClickOutside: PropTypes.func
};
ClickOutsideHandler.defaultProps = {
  children: <span />,
  onClickOutside: () => {},
  style: {}
};

export default ClickOutsideHandler;
