import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';
import ToastContext from './ToastContext';
import ToastComponent from './Toast';

export const TOAST_POSITIONS = [
  'topRight',
  'topLeft',
  'bottomRight',
  'bottomLeft',
  'top',
  'bottom'
];
export const TOAST_VARIANTS = ['info', 'success', 'warning', 'error'];

const DEFAULTS = {
  duration: 5000,
  variant: TOAST_VARIANTS[0],
  position: TOAST_POSITIONS[0]
};

const ToastContainer = props => (
  <div
    className={`Toast-container Toast-container--${props.position}`}
    {...props}
  />
);
ToastContainer.propTypes = {
  position: PropTypes.oneOf(TOAST_POSITIONS)
};

function withToastProvider(Component) {
  function WithToastProvider(props) {
    const [toasts, setToasts] = useState({
      topLeft: [],
      topRight: [],
      bottomLeft: [],
      bottomRight: [],
      top: [],
      bottom: []
    });

    const getDuration = options => {
      if (options.persist) {
        return 0;
      }

      return options.duration ? options.duration : DEFAULTS.duration;
    };

    const add = (content, options = {}) => {
      const position = options.position ? options.position : DEFAULTS.position;
      const variant = options.variant ? options.variant : DEFAULTS.variant;
      const duration = getDuration(options);
      const id = position + ':' + Math.random();

      const newToast = {
        id,
        content,
        options: {
          ...options,
          duration,
          variant
        }
      };

      setToasts({
        ...toasts,
        [position]: [...toasts[position], newToast]
      });
    };

    const remove = id => {
      const position = id.split(':')[0];
      const remainingToasts = toasts[position].filter(t => t.id !== id);
      setToasts({ ...toasts, [position]: remainingToasts });
    };
    return (
      <ToastContext.Provider value={{ add, remove }}>
        <Component {...props} />
        {createPortal(
          <>
            {TOAST_POSITIONS.map(position => (
              <ToastContainer key={position} position={position}>
                {toasts[position].map(t => (
                  <ToastComponent
                    key={t.id}
                    variant={t.options.variant}
                    duration={t.options.duration}
                    withCloseIcon={t.options.withCloseIcon}
                    remove={() => remove(t.id)}
                  >
                    {t.content}
                  </ToastComponent>
                ))}
              </ToastContainer>
            ))}
          </>,
          document.body
        )}
      </ToastContext.Provider>
    );
  }
  return WithToastProvider;
}

export default withToastProvider;
