import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ onThemeChange, theme }) => {
  return (
    <>
      <div className="pt-8 display-flex flex-items-center">
        <div className="flex-auto" />
        <span className="mx-2">Theme</span>
        <select
          value={theme}
          className="mr-3"
          onChange={({ target }) => {
            onThemeChange(target.value);
          }}
        >
          <option value="light">Light</option>
          <option value="dark">Dark</option>
        </select>
      </div>
    </>
  );
};

Header.propTypes = {
  theme: PropTypes.oneOf(['light', 'dark']),
  onThemeChange: PropTypes.func
};

export default Header;
