import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import remarkGfm from 'remark-gfm';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import './Markdown.scss';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  atomOneDark,
  atomOneLight
} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import ThemeContext from '../ThemeContext';
const Markdown = ({ children, className }) => {
  return (
    <ThemeContext.Consumer>
      {theme => (
        <ReactMarkdown
          allowDangerousHtml
          rehypePlugins={[rehypeRaw]}
          remarkPlugins={[remarkGfm]}
          components={{
            code({ node, inline, className, children, ...props }) {
              const match = /language-(\w+)/.exec(className || '');
              return !inline && match ? (
                <SyntaxHighlighter
                  children={String(children).replace(/\n$/, '')}
                  style={theme === 'dark' ? atomOneDark : atomOneLight}
                  language={match[1]}
                  PreTag="div"
                  {...props}
                />
              ) : (
                <code className={className} {...props}>
                  {children}
                </code>
              );
            }
          }}
          className={classnames('Markdown', className)}
        >
          {children}
        </ReactMarkdown>
      )}
    </ThemeContext.Consumer>
  );
};

Markdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'dark'])
};

Markdown.defaultProps = {
  className: ''
};

export default Markdown;
