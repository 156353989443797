import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const api = `
| Name                     | Description                       |
|--------------------------|-----------------------------------|
|\`.display-block\`        | Treats an element as block.       |
|\`.display-inline\`       | Treats an element as inline.      |
|\`.display-inline-block\` | Treats an element as inline-block.|
|\`.display-flex\`         | Treats an element as flex.        |
|\`.display-inline-flex\`  | Treats an element as inline-flex. |
|\`.display-table\`        | Treats an element as table.       |
|\`.display-table-cell\`   | Treats an element as table-cell.  |
|\`.display-none\`         | Hides an element.                 |
`;

const API = ({ id }) => {
  return (
    <Markdown className="mb-7" id={id}>
      {api}
    </Markdown>
  );
};

API.propTypes = {
  id: PropTypes.string
};

export default API;
