import React, { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import FocusLock from 'react-focus-lock';
import { useLockBodyScroll } from '../../hooks/useLockBodyScroll';

const ESC_KEY = 27;
const FADE_IN = 'fade-in';

function ModalComponent({
  isOpen,
  size,
  toggle,
  isClickOutsideDisabled,
  render,
  fullscreen,
  onKeyDown,
  className
}) {
  useLockBodyScroll();

  const modalBackdropRef = useRef(null);
  const modalContainerRef = useRef(null);

  const dismiss = () => {
    modalBackdropRef.current.classList.remove(FADE_IN);
    modalContainerRef.current.classList.remove(FADE_IN);

    setTimeout(() => {
      toggle();
    }, 200);
  };

  const handleContainerClick = e => {
    e.stopPropagation();
  };

  const handleClickOutside = () => {
    if (!isClickOutsideDisabled) {
      dismiss();
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === ESC_KEY && !isClickOutsideDisabled) {
      dismiss();
    }
    onKeyDown(e);
  };

  useEffect(() => {
    const backdropEl = modalBackdropRef.current;
    const containerEl = modalContainerRef.current;

    if (!backdropEl) {
      return;
    }

    const fadeInTimeout = setTimeout(() => {
      backdropEl.classList.add(FADE_IN);
      containerEl.classList.add(FADE_IN);
    }, 100);

    return () => clearTimeout(fadeInTimeout);
  }, [isOpen]);

  const ModalContainerClass = classnames('Modal-container', {
    'Modal-container--small': size === 'small',
    'Modal-container--large': size === 'large',
    'Modal-container--fullscreen': fullscreen
  });

  return (
    <div
      tabIndex="-1"
      className="Modal-backdrop"
      ref={modalBackdropRef}
      onClick={handleClickOutside}
    >
      <FocusLock autoFocus={true} className={className}>
        <div
          tabIndex="-1"
          className={ModalContainerClass}
          ref={modalContainerRef}
          onClick={handleContainerClick}
          onKeyDown={handleKeyDown}
        >
          {render(dismiss)}
        </div>
      </FocusLock>
    </div>
  );
}

ModalComponent.propTypes = {
  cancelButtonText: PropTypes.string,
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  isClickOutsideDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  render: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  toggle: PropTypes.func,
  onKeyDown: PropTypes.func
};

ModalComponent.defaultProps = {
  size: 'default',
  fullscreen: false,
  onKeyDown: () => {}
};

const Modal = ({ children, ...restProps }) =>
  createPortal(
    <ModalComponent {...restProps}>{children}</ModalComponent>,
    document.body
  );

export default Modal;
