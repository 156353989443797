import React from 'react';
import PropTypes from 'prop-types';

const Logo = ({ isIcon = false, size = 32, style }) => {
  return (
    <div style={{ fontSize: size, ...style }} className="d-inline-block logo">
      {isIcon ? 'B' : 'A'}
    </div>
  );
};

Logo.propTypes = {
  className: PropTypes.string,
  isIcon: PropTypes.bool,
  size: PropTypes.number,
  style: PropTypes.object
};

export default Logo;
