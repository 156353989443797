import React from 'react';
import PaddingExample from './PaddingExample';
import API from './API';
import BREAKPOINTS from './BREAKPOINTS';

const Padding = () => (
  <div>
    <h1>Padding utility</h1>
    <API />
    <h1>Code examples</h1>
    <PaddingExample />
    <BREAKPOINTS />
  </div>
);
export default Padding;
