import React from 'react';
import Preview from '../../modules/Preview';

const TextFields = () => (
  <Preview title="Text Fields">
    <div className="cols mb-7">
      {/* Default */}
      <div className="col col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="display-flex flex-column flex-items-start">
          <span className="py-2">Default</span>
          <div className="display-flex width-full">
            <input
              type="text"
              className="TextField flex-auto"
              placeholder="Text field"
            />
            <div className="mr-3" />
          </div>
        </div>
      </div>
      {/* Hover */}
      <div className="col col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="display-flex flex-column flex-items-start">
          <span className="py-2">Hover</span>
          <div className="display-flex width-full">
            <input
              type="text"
              className="TextField TextField--hovered"
              placeholder="Text field"
            />
            <div className="mr-3" />
          </div>
        </div>
      </div>
      {/* Selected */}
      <div className="col col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="display-flex flex-column flex-items-start">
          <span className="py-2">Disabled</span>
          <div className="display-flex width-full">
            <input
              type="text"
              className="TextField TextField--disabled"
              placeholder="Text field"
              disabled
            />
            <div className="mr-3" />
          </div>
        </div>
      </div>
    </div>
  </Preview>
);

export default TextFields;
