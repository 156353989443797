import React from 'react';
import Preview from '../../modules/Preview';

const Slider = () => {
  return (
    <Preview title="Slider">
      <div className="cols">
        <div className="col col-12">
          <div className="Slider">
            <div className="Slider-track left-0 right-0" />
            <div className="Slider-thumb" style={{ left: 20 }}>
              20
            </div>
          </div>
        </div>
      </div>
    </Preview>
  );
};

export default Slider;
