import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const breakpoints = `
> Breakpoints are also supported (xs, sm, md, lg, xl).

| Name                        | Description                                |
|-----------------------------|--------------------------------------------|
|\`.{breakpoint}:m-{scale}\`  | Sets margin to all sides.                  |
|\`.{breakpoint}:mt-{scale}\` | Sets margin-top.                           |
|\`.{breakpoint}:mr-{scale}\` | Sets margin-right.                         |
|\`.{breakpoint}:mb-{scale}\` | Sets margin-bottom.                        |
|\`.{breakpoint}:ml-{scale}\` | Sets margin-left.                          |
|\`.{breakpoint}:mx-{scale}\` | Sets margin-right and margin-left.         |
|\`.{breakpoint}:my-{scale}\` | Sets margin-top and margin-bottom.         |
|\`.{breakpoint}:mx-auto\`    | Sets margin-right and margin-left to auto. |
`;

const BREAKPOINTS = ({ id }) => {
  return <Markdown id={id}>{breakpoints}</Markdown>;
};

BREAKPOINTS.propTypes = {
  id: PropTypes.string
};

export default BREAKPOINTS;
