import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useBreakpoint } from '../hooks/useBreakpoint';
import ClickOutsideHandler from './ClickOutsideHandler';

const burgerStyleLines = { height: 1, width: 30 };

const Sidenav = ({ children, theme }) => {
  const { breakpoint } = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  const isUnderLg = bp => {
    if (bp === 'xs' || bp === 'sm' || bp === 'md') {
      return true;
    }
    return false;
  };
  if (isUnderLg(breakpoint) && !isOpen) {
    return (
      <div
        className={classnames(
          'position-fixed top-0 left-0 right-0 width-full',
          {
            'bg-black': theme === 'dark',
            'bg-white': theme === 'light'
          }
        )}
        style={{ height: 50, zIndex: 10 }}
      >
        <div className="display-flex flex-column height-full p-2">
          <div className="flex-auto display-flex">
            <div className="cursor-pointer" onClick={() => setIsOpen(true)}>
              <div className="border" style={burgerStyleLines} />
              <div className="p-1" />
              <div className="border" style={burgerStyleLines} />
              <div className="p-1" />
              <div className="border" style={burgerStyleLines} />
            </div>
            <div className="flex-auto" />
          </div>
          <div className="border" style={{ height: 1 }} />
        </div>
      </div>
    );
  }

  return (
    <>
      {!isOpen && (
        <div style={{ flex: '0 0 320px', zIndex: 10 }} />
      )}
      <ClickOutsideHandler
        style={{ zIndex: 10, width: 320 }}
        className={classnames('position-fixed top-0 bottom-0 left-0', {
          'bg-gray-600': theme === 'dark',
          'bg-gray-200': theme === 'light'
        })}
        onClickOutside={() => {
          if (isUnderLg) {
            setIsOpen(false);
          }
        }}
      >
        <div className="pt-5 pl-4 pr-4">{children}</div>
      </ClickOutsideHandler>
    </>
  );
};

Sidenav.propTypes = {
  children: PropTypes.node,
  theme: PropTypes.string
};

export default Sidenav;
