import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const api = `
> Scale values: (1, 2, 3, 4, 5, 6, 7).

| Name           | Description                                 |
|----------------|---------------------------------------------|
|\`.m-{scale}\`  | Sets margin to all sides.                   |
|\`.mt-{scale}\` | Sets margin-top.                            |
|\`.mr-{scale}\` | Sets margin-right.                          |
|\`.mb-{scale}\` | Sets margin-bottom.                         |
|\`.ml-{scale}\` | Sets margin-left.                           |
|\`.mx-{scale}\` | Sets margin-right and margin-left.          |
|\`.my-{scale}\` | Sets margin-top and margin-bottom.          |
|\`.mx-auto\`    | Sets margin-right and margin-left to auto.  |
`;

const API = ({ id }) => {
  return (
    <Markdown className="mb-7" id={id}>
      {api}
    </Markdown>
  );
};

API.propTypes = {
  id: PropTypes.string
};

export default API;
