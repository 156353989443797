import { useState, useEffect } from 'react';
import breakpoints from '../constants/breakpoints';

function debounce(func, wait, immediate) {
  let timeout;
  return function() {
    const context = this,
      args = arguments;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
}

const getBreakpoint = width => {
  if (width < breakpoints.sm) {
    return 'xs';
  }
  if (width < breakpoints.md) {
    return 'sm';
  }
  if (width < breakpoints.lg) {
    return 'md';
  }
  if (width < breakpoints.xl) {
    return 'lg';
  }
  if (width > breakpoints.xl) {
    return 'xl';
  }
};

function useBreakpoint() {
  if (typeof window === 'undefined') {
    return { breakpoint: '' };
  }

  const [width, setWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      debounce(setWidth(window.innerWidth), 200);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  return { breakpoint: getBreakpoint(width) };
}

export { useBreakpoint };
