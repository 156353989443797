import React from 'react';
import Preview from '../../modules/Preview';

const Legend = () => {
  return (
    <Preview title="Legend">
      <div className="cols mb-7">
        {/* Default */}
        <div className="col col-offset-md-1 col-xs-12 col-sm-12 col-md-11 col-lg-11">
          <div className="display-flex flex-column flex-items-start">
            <span className="py-2">Default</span>
            <div className="display-flex width-full">
              <legend className="mt-3">Legend</legend>
            </div>
          </div>
        </div>
      </div>
    </Preview>
  );
};

export default Legend;
