import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const api = `
| Name              | Description                       |
|-------------------|-----------------------------------|
|\`.border\`        | Displays the border to all sides. |
|\`.border-0\`      | Hides the border from all sides.  |
|\`.border-top\`    | Displays a top side border.       |
|\`.border-right\`  | Displays a right side border.     |
|\`.border-bottom\` | Displays a bottom side border.    |
|\`.border-left\`   | Displays a left side border.      |
`;

const API = ({ id }) => {
  return (
    <Markdown className="mb-7" id={id}>
      {api}
    </Markdown>
  );
};

API.propTypes = {
  id: PropTypes.string
};

export default API;
