import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const breakpoints = `
## Breakpoints are also supported (xs, sm, md, lg, xl).
| Name                            | Description                                           |
|---------------------------------|-------------------------------------------------------|
|\`.{breakpoint}:border\`         | Displays the border to all sides for this breakpoint. |
|\`.{breakpoint}:border-0\`       | Hides the border from all sides for this breakpoint.  |
|\`.{breakpoint}:border-top\`     | Displays a top side border for this breakpoint.       |
|\`.{breakpoint}:border-right\`   | Displays a right side border for this breakpoint.     |
|\`.{breakpoint}:border-bottom\`  | Displays a bottom side border for this breakpoint.    |
|\`.{breakpoint}:border-left\`    | Displays a left side border for this breakpoint.      |
`;

const BREAKPOINTS = ({ id }) => {
  return <Markdown id={id}>{breakpoints}</Markdown>;
};

BREAKPOINTS.propTypes = {
  id: PropTypes.string
};

export default BREAKPOINTS;
