import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TabsProvider from './TabsProvider';

const Tabs = ({ className, activeIndex, onSelect, defaultIndex, children }) => (
  <TabsProvider
    activeIndex={activeIndex}
    onSelect={onSelect}
    defaultIndex={defaultIndex}
  >
    <div className={classnames('Tabs', className)}>{children}</div>
  </TabsProvider>
);

Tabs.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
  defaultIndex: PropTypes.number
};

Tabs.defaultProps = {
  className: ''
};

export default Tabs;
