import React from 'react';
import PropTypes from 'prop-types';
import MarkdownIt from 'markdown-it';
import emoji from 'markdown-it-emoji';
import taskLists from 'markdown-it-task-lists';
import multimdTable from 'markdown-it-multimd-table';
import classnames from 'classnames';
import Prism from 'prismjs';
import 'prismjs/components/prism-yaml';
import 'prismjs/components/prism-bash';

import ThemeContext from './ThemeContext';

const md = new MarkdownIt({
  html: true,
  linkify: true,
  highlight: (str, lang) => {
    if (Prism.languages[lang] !== undefined) {
      return Prism.highlight(str, Prism.languages[lang]);
    }
    return '';
  }
})
  .use(emoji)
  .use(taskLists)
  .use(multimdTable);

const Markdown = ({ children, className }) => (
  <ThemeContext.Consumer>
    {theme => {
      if (theme === undefined) {
        throw new Error('CountConsumer must be used within a CountProvider');
      }

      return (
        <div
          className={classnames('Markdown', className, {
            'MarkdownTheme--dark': theme === 'dark',
            'MarkdownTheme--light': theme === 'light'
          })}
          dangerouslySetInnerHTML={{ __html: md.render(children) }}
        />
      );
    }}
  </ThemeContext.Consumer>
);

Markdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

Markdown.defaultProps = {
  className: ''
};

export default Markdown;
