import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const api = `
> Scale values: (1, 2, 3, 4, 5, 6, 7).

| Name           | Description                                  |
|----------------|----------------------------------------------|
|\`.p-{scale}\`  | Sets padding to all sides.                   |
|\`.pt-{scale}\` | Sets padding-top.                            |
|\`.pr-{scale}\` | Sets padding-right.                          |
|\`.pb-{scale}\` | Sets padding-bottom.                         |
|\`.pl-{scale}\` | Sets padding-left.                           |
|\`.px-{scale}\` | Sets padding-right and padding-left.         |
|\`.py-{scale}\` | Sets padding-top and padding-bottom.         |
|\`.px-auto\`    | Sets padding-right and padding-left to auto. |
`;

const API = ({ id }) => {
  return (
    <Markdown className="mb-7" id={id}>
      {api}
    </Markdown>
  );
};

API.propTypes = {
  id: PropTypes.string
};

export default API;
