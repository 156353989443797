import React from 'react';
import PropTypes from 'prop-types';
import Font from '../../modules/Font';
import Preview from '../../modules/Preview';

const colorBox = {
  width: 65,
  height: 65,
  borderRadius: 18
};

const colours = [
  { name: 'Black', value: 'bg-black', border: 'white' },
  { name: 'Purple', value: 'bg-purple' },
  { name: 'Teal', value: 'bg-teal' },
  { name: 'Green', value: 'bg-green' },
  { name: 'Blue', value: 'bg-blue' },
  { name: 'Red', value: 'bg-red' },
  { name: 'Orange', value: 'bg-orange' },
  { name: 'Pink', value: 'bg-pink' }
];

const grays = [
  { name: 'White', value: 'bg-white', border: 'black' },
  { name: 'Gray 100', value: 'bg-gray-100' },
  { name: 'Gray 200', value: 'bg-gray-200' },
  { name: 'Gray 300', value: 'bg-gray-300' },
  { name: 'Gray 400', value: 'bg-gray-400' },
  { name: 'Gray 500', value: 'bg-gray-500' },
  { name: 'Gray 600', value: 'bg-gray-600' },
  { name: 'Gray 700', value: 'bg-gray-700' }
];

const Colour = ({ value, name, border }) => {
  const boxStyle = {
    ...colorBox,
    ...(border && { border: `1px solid ${border}` })
  };
  return (
    <div className="display-flex flex-items-center mt-3">
      <div style={boxStyle} className={value} />
      <div className="ml-3">
        <Font size={16}>{name}</Font>
      </div>
    </div>
  );
};

Colour.propTypes = {
  border: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string
};

const Colours = () => {
  return (
    <Preview title="Colours">
      <div className="display-flex">
        <div className="display-flex flex-column mr-7">
          {colours.map(colour => (
            <Colour key={colour.value} {...colour} />
          ))}
        </div>
        <div className="display-flex flex-column">
          {grays.map(colour => (
            <Colour key={colour.value} {...colour} />
          ))}
        </div>
      </div>
    </Preview>
  );
};

export default Colours;
