import React from 'react';
import Font from '../../modules/Font';

const Intro = () => (
  <Font
    className="display-block mt-7 color-teal"
    size={40}
    isItalic
    weight={600}
  >
    Welcome to Chatty Design System!
  </Font>
);

export default Intro;
