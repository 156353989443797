import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

const Font = ({ size, weight, isItalic, children, className }) => {
  const style = {
    fontSize: size,
    fontStyle: isItalic ? 'italic' : 'normal',
    fontWeight: weight
  };

  return (
    <span className={classnames(className)} style={style}>
      {children}
    </span>
  );
};

Font.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isItalic: PropTypes.bool,
  size: PropTypes.number,
  weight: PropTypes.oneOf([300, 400, 500, 600, 700, 800])
};

Font.defaultProps = {
  isItalic: false
};

export default Font;
