import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TabsContext } from './TabsContext';

const TabPanels = ({ children, className }) => (
  <TabsContext.Consumer>
    {context => (
      <div className={classnames('Tab-panels', className)}>
        {children[context.activeIndex]}
      </div>
    )}
  </TabsContext.Consumer>
);

TabPanels.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default TabPanels;
