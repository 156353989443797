import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Timer from './Timer';

const Toast = ({ children, variant, duration, withCloseIcon, remove }) => {
  const [show, setShow] = useState(true);
  const [isPaused, setIsPaused] = useState(false);
  const [timer, setTimer] = useState();

  const removeRef = useRef(null);
  removeRef.current = remove;

  useEffect(() => {
    if (!duration) {
      return;
    }

    const timer = new Timer(() => {
      setShow(false);
    }, duration);

    setTimer(timer);

    return () => clearTimeout(timer);
  }, [duration]);

  const setToRemove = () => {
    setShow(false);
  };

  const onMouseOver = () => {
    if (isPaused || !timer) {
      return;
    }

    timer.pause();

    setIsPaused(true);
  };

  const onMouseLeave = () => {
    if (!isPaused || !timer) {
      return;
    }

    timer.resume();

    setIsPaused(false);
  };

  const onAnimationEnd = () => {
    if (!show) {
      removeRef.current();
    }
  };

  const wrapperClasses = classnames('Toast', {
    'Toast--visible': show,
    'Toast--hidden': !show
  });

  return (
    <div
      className={wrapperClasses}
      onAnimationEnd={onAnimationEnd}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
    >
      <div className={`Toast--${variant}`}>
        {withCloseIcon && (
          <div className="Toast--dismissButton" onClick={setToRemove} />
        )}
        {children}
      </div>
    </div>
  );
};

Toast.propTypes = {
  children: PropTypes.node,
  duration: PropTypes.number,
  remove: PropTypes.func,
  variant: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
  withCloseIcon: PropTypes.bool
};

Toast.defaultProps = {
  variant: 'info',
  duration: 5000,
  persist: true,
  withCloseIcon: true,
  postion: 'topRight'
};

export default Toast;
