import React, { useState } from 'react';
import Preview from '../../modules/Preview';

const Status = () => {
  const [isAwayTimeShown, setIsAwayTimeShown] = useState();
  return (
    <Preview title="Status">
      <div className="cols">
        {/* Default */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Online</span>
            <span className="Status Status--online" />
          </div>
        </div>
        {/* Hover */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Offline</span>
            <span className="Status Status--offline" />
          </div>
        </div>
        {/* Selected */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Away</span>
            <span
              className="Status Status--away"
              onMouseEnter={() => setIsAwayTimeShown(true)}
              onMouseLeave={() => setIsAwayTimeShown(false)}
            >
              {isAwayTimeShown && <div className="Status-body">35m</div>}
            </span>
          </div>
        </div>
      </div>
    </Preview>
  );
};

export default Status;
