import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import withToastProvider from '../../../modules/toasts/withToastProvider';
import useToast from '../../../modules/toasts/useToast';
import toastMd from './toast.md';

const ToastExample = ({ id }) => {
  const toast = useToast();

  const handleCreateToast = () => {
    toast.add('You have a new message!', {
      withCloseIcon: true,
      variant: 'info',
      persist: false,
      position: 'topRight',
      duration: 4000
    });
  };
  return (
    <CodeExample
      description="Displays important notifications to user"
      codeSnippet={toastMd}
      id={id}
      isJsx
    >
      <button className="Button Button--primary" onClick={handleCreateToast}>
        Click me!
      </button>
    </CodeExample>
  );
};

ToastExample.propTypes = {
  id: PropTypes.string
};

export default withToastProvider(ToastExample);
