import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const breakpoints = `
## Breakpoints also supported (xs, sm, md, lg, xl).
| Name                     | Description                                                         |
|--------------------------|---------------------------------------------------------------------|
|\`.{breakpoint}:display-block\`        | Treats an element as block for this breakpoint.        |
|\`.{breakpoint}:display-inline\`       | Treats an element as inline for this breakpoint.       |
|\`.{breakpoint}:display-inline-block\` | Treats an element as inline-block for this breakpoint. |
|\`.{breakpoint}:display-flex\`         | Treats an element as flex.                             |
|\`.{breakpoint}:display-inline-flex\`  | Treats an element as inline-flex.                      |
|\`.{breakpoint}:display-table\`        | Treats an element as table.                            |
|\`.{breakpoint}:display-table-cell\`   | Treats an element as table-cell.                       |
|\`.{breakpoint}:display-none\`         | Hides an element.                                      |
`;

const BREAKPOINTS = ({ id }) => {
  return <Markdown id={id}>{breakpoints}</Markdown>;
};

BREAKPOINTS.propTypes = {
  id: PropTypes.string
};

export default BREAKPOINTS;
