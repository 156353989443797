import React from 'react';
import BorderExample from './BorderExample';
import API from './API';
import BREAKPOINTS from './BREAKPOINTS';
import Border2Example from './Border2Example';

const Border = () => (
  <div>
    <h1>CSS Border utility</h1>
    <API />
    <h1>Code examples</h1>
    <BorderExample />
    <BREAKPOINTS />
    <br />
    <h1>
      Bigger borders with <code>.border-2</code>
    </h1>
    <Border2Example />
  </div>
);
export default Border;
