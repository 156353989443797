import React from 'react';
import PropTypes from 'prop-types';
import Font from '../../modules/Font';
import Preview from '../../modules/Preview';

const typefaces = [
  { label: 'Light', weight: 300 },
  { label: 'Regular', weight: 400 },
  { label: 'Medium', weight: 500 },
  { label: 'Bold', weight: 600 },
  { label: 'Heavy', weight: 700 },
  { label: 'Ultra', weight: 800 }
];

const Typeface = ({ isItalic = false }) => (
  <>
    <div className="cols">
      <div className="col col-md-1 col-sm-12 display-md-block display-xs-none">
        &nbsp;
      </div>
      <div className="col col-md-4 col-sm-12 display-md-block display-xs-none my-2">
        <Font size={16}>Latin</Font>
      </div>
      <div className="col col-md-4 col-sm-12 display-md-block display-xs-none my-2">
        <Font size={16}>Greek</Font>
      </div>
      <div className="col col-md-3 col-sm-12 display-md-block display-xs-none my-2">
        <Font size={16}>Numbers & Symbols</Font>
      </div>
    </div>
    {typefaces.map(({ label, weight }) => (
      <div key={label} className="cols">
        <div className="col col-md-1 col-sm-12 col-xs-12 mt-2">
          <Font size={16}>{label}</Font>
        </div>
        <div className="col col-md-4 col-sm-12 col-xs-12 mt-2">
          <Font size={16} weight={weight} isItalic={isItalic}>
            ABCDEFGHIJKLMNOPQRSTUVTXYZ <br />
            abcdefghijklmnopqrstuvwxyz
          </Font>
        </div>
        <div className="col col-md-4 col-sm-12 col-xs-12 mt-2">
          <Font size={16} weight={weight} isItalic={isItalic}>
            ΑΒΓΔΕΖΗΘΙΚΛΜΝΞΟΠΡΣΤΥΦΧΨΩ <br />
            αβγδεζηθικλμνξοπρσςτυφχψω
          </Font>
        </div>
        <div className="col col-md-3 col-sm-12 col-xs-12 mt-2">
          <Font size={16} weight={weight} isItalic={isItalic}>
            1234567890(#$%&@!?)
          </Font>
        </div>
      </div>
    ))}
  </>
);

Typeface.propTypes = {
  isItalic: PropTypes.bool
};

const Typography = () => {
  return (
    <div className="width-full display-flex">
      <div>
        <Preview title="Typography - Chatty Roman">
          <Typeface />
        </Preview>
        <Preview title="Typography - Chatty Italics">
          <Typeface isItalic />
        </Preview>
      </div>
    </div>
  );
};

export default Typography;
