import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import paddingMd from './padding.md';

const PaddingExample = ({ id }) => {
  return (
    <CodeExample codeSnippet={paddingMd} id={id} jsx>
      <span className="p-2 border m-2">.p-2</span>
      <span className="pt-2 border m-2">.pt-2</span>
      <span className="pr-2 border m-2">.pr-2</span>
      <span className="pb-2 border m-2">.pb-2</span>
      <span className="pl-2 border m-2">.pl-2</span>
      <span className="px-2 border m-2">.px-2</span>
      <span className="py-2 border m-2">.py-2</span>
    </CodeExample>
  );
};

PaddingExample.propTypes = {
  id: PropTypes.string
};

export default PaddingExample;
