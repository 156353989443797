import React from 'react';
import Logo from '../../modules/Logo';
import Preview from '../../modules/Preview';

const Logos = () => {
  return (
    <Preview title="Logo">
      <div className="mt-4">
        <Logo isIcon size={80} />
      </div>
      <div>
        <Logo size={80} />
      </div>
    </Preview>
  );
};

export default Logos;
