import React, { useState } from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import Modal from '../../../modules/modal';
import modalMd from './modal.md';

const ModalExample = ({ id }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <CodeExample
      description="Displays a Modal to the user."
      codeSnippet={modalMd}
      id={id}
    >
      <button
        className="Button Button--primary"
        onClick={() => {
          setIsOpen(true);
        }}
      >
        Open Modal
      </button>
      <Modal
        isOpen={isOpen}
        toggle={() => setIsOpen(false)}
        render={dismiss => (
          <div className="">
            <div className="display-flex border-bottom-2 flex-items-center">
              <span className="f-4 display-inline-block p-3">Modal Title</span>
              <div className="flex-auto" />
              <button className="mr-3" onClick={dismiss}>
                X
              </button>
            </div>
            <div className="p-7">
              The content of the Modal here.
              <br />
              <br />
              Other important content here..
            </div>
          </div>
        )}
      />
    </CodeExample>
  );
};

ModalExample.propTypes = {
  id: PropTypes.string
};

export default ModalExample;
