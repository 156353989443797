import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TabsContext } from './TabsContext';

class TabsProvider extends Component {
  constructor(props) {
    super(props);
    this.onSelect = ({ index }) => {
      this.setState(() => ({
        activeIndex: index
      }));
    };
    this.state = {
      activeIndex: props.activeIndex || (props.defaultIndex || 0),
      onSelect: this.onSelect
    };
  }
  isControlled() {
    return this.props.activeIndex != null;
  }
  render() {
    const { children, activeIndex, onSelect } = this.props;
    return (
      <TabsContext.Provider
        value={this.isControlled() ? { activeIndex, onSelect } : this.state}
      >
        {children}
      </TabsContext.Provider>
    );
  }
}

TabsProvider.propTypes = {
  activeIndex: PropTypes.number,
  onSelect: PropTypes.func,
  children: PropTypes.node,
  defaultIndex: PropTypes.number
};

export default TabsProvider;
