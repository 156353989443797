import React from 'react';
import Preview from '../../modules/Preview';

const RadioButtons = () => (
  <Preview title="Radio Buttons">
    <div className="cols mb-7">
      {/* Default */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Default</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="RadioButton">
              <input
                className="RadioButton-input"
                id="radio"
                type="radio"
                name="radio"
              />
              <label htmlFor="radio" className="RadioButton-label">
                Radio button
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Checked */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Checked</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="RadioButton">
              <input
                className="RadioButton-input RadioButton-input--checked"
                type="radio"
                name="radio"
              />
              <label className="RadioButton-label">Radio button</label>
            </div>
          </div>
        </div>
      </div>
      {/* Hovered */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Hover</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="RadioButton">
              <input
                className="RadioButton-input RadioButton-input--hovered"
                type="radio"
                name="radio"
                id="radio-hover"
              />
              <label htmlFor="radio-hover" className="RadioButton-label">
                Radio button
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Disabled */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Disabled</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="RadioButton">
              <input
                className="RadioButton-input RadioButton-input--disabled"
                type="radio"
                name="radio"
                disabled
              />
              <label className="RadioButton-label">Radio button</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Preview>
);

export default RadioButtons;
