import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Route,
  BrowserRouter as Router,
  Switch,
  NavLink
} from 'react-router-dom';
import ThemeContext from './modules/ThemeContext';
import Logo from './modules/Logo';
import Font from './modules/Font';
import Sidenav from './modules/Sidenav';
import Sections from './pages/sections';
import Header from './modules/Header';
import ReleaseNotes from './pages/ReleaseNotes';
import Toast from './pages/react-components/toast';
import Modal from './pages/react-components/modal';
import Tabs from './pages/react-components/tabs';
import Border from './pages/css-utilities/border';
import Display from './pages/css-utilities/display';
import Margin from './pages/css-utilities/margin';
import Padding from './pages/css-utilities/padding';

const App = () => {
  const passwordRef = useRef(null);
  const password = localStorage.getItem('password');
  if (!password || password !== 'Y2hhdHR5MzM=') {
    return (
      <div className="display-flex flex-items-center flex-justify-center height-full width-full">
        <form
          className="display-inline-block"
          onSubmit={e => {
            e.preventDefault();
            localStorage.setItem('password', btoa(passwordRef.current.value));
            passwordRef.current.value = '';
            location.reload();
          }}
        >
          <div className="display-flex width-full">
            <span className="display-block mr-2">Enter password:</span>
            <input type="password" ref={passwordRef} />
            <input className="ml-2" type="submit" value="Join" />
          </div>
        </form>
      </div>
    );
  }

  const storedTheme = localStorage.getItem('proteus-theme')
    ? localStorage.getItem('proteus-theme')
    : 'dark';
  const [theme, setTheme] = useState(storedTheme);
  useEffect(() => {
    localStorage.setItem('proteus-theme', theme);
    document.body.className = `chatty chatty--${theme}`;
  }, [theme]);
  return (
    <Router>
      <ThemeContext.Provider value={theme}>
        <div className="viewport">
          <div className="display-flex flex-column flex-auto">
            <div className="display-flex width-full height-full">
              <Sidenav theme={theme}>
                <div>
                  <a
                    href="/"
                    style={{ textDecoration: 'none', color: 'inherit' }}
                  >
                    <Logo />
                    <Font className="mt-1 ml-1" size={24} weight={600}>
                      Design System
                    </Font>
                  </a>
                </div>
                <div className="mt-6">
                  <NavLink className="Link" to="/">
                    Home
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/releases">
                    Release Notes
                  </NavLink>
                </div>
                <small className="mt-3 display-block">
                  <strong>CSS UTILITIES</strong>
                </small>
                <div className="mt-3">
                  <NavLink className="Link" to="/border">
                    Border
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/display">
                    Display
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/margin">
                    Margin
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/padding">
                    Padding
                  </NavLink>
                </div>
                <small className="mt-3 display-block">
                  <strong>REACT COMPONENTS</strong>
                </small>
                <div className="mt-3">
                  <NavLink className="Link" to="/toast">
                    Toast
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/modal">
                    Modal
                  </NavLink>
                </div>
                <div className="mt-3">
                  <NavLink className="Link" to="/tabs">
                    Tabs
                  </NavLink>
                </div>
              </Sidenav>

              <div className="flex-auto width-full">
                <div className="width-full display-flex flex-justify-center">
                  <div
                    className="width-full mx-2 mb-7"
                    style={{ maxWidth: 900 }}
                  >
                    <Header onThemeChange={setTheme} theme={theme} />
                    <Switch>
                      <Route exact path="/" component={Sections} />
                      <Route path="/releases" component={ReleaseNotes} />
                      <Route path="/toast" component={Toast} />
                      <Route path="/modal" component={Modal} />
                      <Route path="/tabs" component={Tabs} />
                      <Route path="/border" component={Border} />
                      <Route path="/display" component={Display} />
                      <Route path="/margin" component={Margin} />
                      <Route path="/padding" component={Padding} />
                    </Switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ThemeContext.Provider>
    </Router>
  );
};

App.propTypes = {
  me: PropTypes.object
};

export default App;
