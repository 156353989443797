import React from 'react';
import Preview from '../../modules/Preview';

const Link = () => {
  return (
    <Preview title="Link">
      <a className="Link" href="#">
        This is a link
      </a>
    </Preview>
  );
};

export default Link;
