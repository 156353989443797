import React from 'react';
import PropTypes from 'prop-types';
import Modal from './Modal';

const ModalContainer = props => {
  if (!props.isOpen) {
    return null;
  }

  return <Modal {...props} />;
};

ModalContainer.propTypes = {
  cancelButtonText: PropTypes.string,
  className: PropTypes.string,
  fullscreen: PropTypes.bool,
  isClickOutsideDisabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  render: PropTypes.func,
  size: PropTypes.oneOf(['small', 'default', 'large']),
  toggle: PropTypes.func,
  onKeyDown: PropTypes.func
};

export default ModalContainer;
