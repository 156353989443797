import React from 'react';
import classnames from 'classnames';
import Preview from '../../modules/Preview';

const buttonStyle = { width: '100%', maxWidth: 300 };

const buttons = [
  { label: 'CTA', className: 'Button--cta' },
  { label: 'Primary', className: 'Button--primary' },
  { label: 'Secondary', className: 'Button--secondary' },
  { label: 'Danger', className: 'Button--danger' }
];

const Buttons = () => {
  return (
    <Preview title="Buttons">
      <div className="cols">
        <div className="col col-md-2 col-sm-12 display-md-block display-xs-none">
          &nbsp;
        </div>
        <div className="col col-md-3 col-sm-12 display-md-block display-xs-none my-2">
          <div className="display-flex flex-justify-center">Default</div>
        </div>
        <div className="col col-md-3 col-sm-12 display-md-block display-xs-none my-2">
          <div className="display-flex flex-justify-center">Hover</div>
        </div>
        <div className="col col-md-3 col-sm-12 display-md-block display-xs-none my-2">
          <div className="display-flex flex-justify-center">Disabled</div>
        </div>
      </div>
      {buttons.map(button => (
        <div key={button.label} className="cols">
          <div className="col col-md-2 col-sm-12 col-xs-12 mt-2">
            <div
              style={{ height: 50 }}
              className="display-flex flex-column flex-lg-items-end flex-md-items-start flex-justify-center mr-5"
            >
              {button.label}
            </div>
          </div>
          <div className="col col-md-3 col-sm-12 col-xs-12 mt-2">
            <button
              style={buttonStyle}
              className={classnames('Button', button.className)}
            >
              {button.label}
            </button>
          </div>
          <div className="col col-md-3 col-sm-12 col-xs-12 mt-2">
            <button
              style={buttonStyle}
              className={classnames(
                'Button',
                button.className,
                'Button--hovered'
              )}
            >
              {' '}
              {button.label}
            </button>
          </div>
          <div className="col col-md-3 col-sm-12 col-xs-12 mt-2">
            <button
              style={buttonStyle}
              disabled
              className={classnames(
                'Button',
                button.className,
                'Button--disabled'
              )}
            >
              {button.label}
            </button>
          </div>
        </div>
      ))}
    </Preview>
  );
};

export default Buttons;
