import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Preview = ({ title, children, to }) => (
  <div>
    <legend>{to ? <Link to={to}>{title}</Link> : <span>{title}</span>}</legend>
    <div>{children}</div>
  </div>
);

Preview.defaultProps = {
  to: null
};

Preview.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.string
};

export default Preview;
