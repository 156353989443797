import React from 'react';
import MarginExample from './MarginExample';
import API from './API';
import BREAKPOINTS from './BREAKPOINTS';

const Margin = () => (
  <div>
    <h1>Margin utility</h1>
    <API />
    <h1>Code examples</h1>
    <MarginExample />
    <BREAKPOINTS />
  </div>
);
export default Margin;
