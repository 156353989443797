import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const Tab = ({ children, isActive, onSelect, isDisabled, className }) => (
  <button
    className={classnames('Tab', className, {
      'Tab--active': isActive
    })}
    disabled={isDisabled}
    onClick={isDisabled ? null : onSelect}
  >
    <div className="display-flex flex-items-center Tab--container">
      {children}
    </div>
  </button>
);

Tab.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  isActive: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSelect: PropTypes.func
};

export default Tab;
