import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Markdown from './markdown/Markdown';

const CodeExample = ({
  children,
  codeSnippet,
  title,
  description,
  id,
  width
}) => {
  const [isCodeExpanded, setIsCodeExpanded] = useState(false);

  return (
    <div
      style={{ border: '1px solid transparent' }}
      className="mt-7 width-full"
      id={id}
    >
      {title && <h2 className="mt-7">{title}</h2>}
      {description && <Markdown>{description}</Markdown>}
      <div className="Box mt-2 mb-7">
        <div className="display-flex">
          <div className="flex-auto" style={{ width: width }}>
            {children}
          </div>
        </div>
        {!isCodeExpanded && (
          <div
            className="mt-3 width-full position-relative overflow-hidden cursor-pointer"
            style={{ maxHeight: 240 }}
            onClick={() => {
              setIsCodeExpanded(true);
            }}
          >
            <div
              className="position-absolute left-0 right-0"
              style={{ zIndex: 2, bottom: 20 }}
            >
              <div className="display-flex flex-justify-center width-full">
                <span>⬇ Expand ⬇</span>
              </div>
            </div>

            <div className="Code-fade" />

            <Markdown>{codeSnippet}</Markdown>
          </div>
        )}
        {isCodeExpanded && (
          <>
            <div className="width-full">
              <Markdown>{codeSnippet}</Markdown>
            </div>

            <div
              className="display-flex flex-justify-center width-full cursor-pointer"
              onClick={() => {
                setIsCodeExpanded(false);
              }}
            >
              <span>⬆ Colapse ⬆</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

CodeExample.propTypes = {
  children: PropTypes.node,
  codeSnippet: PropTypes.string,
  description: PropTypes.node,
  id: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string
};

CodeExample.defaultProps = {
  codeSnippet: '',
  title: '',
  description: '',
  id: '',
  width: 'initial'
};

export default CodeExample;
