import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import displayMd from './display.md';

const DisplayExample = ({ id }) => {
  return (
    <CodeExample codeSnippet={displayMd} id={id} jsx>
      <div className="display-block">.display-block</div>
      <div className="display-inline">.display-inline</div>
      <div className="display-inline-block">.display-inline-block</div>
      <div className="display-flex">.display-flex</div>
      <div className="display-inline-flex">.display-inline-flex</div>
      <div className="display-table">.display-table</div>
      <div className="display-table-cell">.display-table-cell</div>
      <div className="display-none">.display-none</div>
    </CodeExample>
  );
};

DisplayExample.propTypes = {
  id: PropTypes.string
};

export default DisplayExample;
