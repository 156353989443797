import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { TabsContext } from './TabsContext';

const TabList = ({ className, children }) => (
  <TabsContext.Consumer>
    {context => {
      const enhancedChildren = React.Children.map(children, (child, index) => {
        if (child === null) return null;
        const { id } = child.props;
        return React.cloneElement(child, {
          isActive: index === context.activeIndex,
          onSelect: () => {
            context.onSelect({ index, id });
          }
        });
      });
      return (
        <div className={classnames('Tab-list', className)}>
          {enhancedChildren}
        </div>
      );
    }}
  </TabsContext.Consumer>
);

TabList.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default TabList;
