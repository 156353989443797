import React from 'react';
//import Avatar from '../../modules/Avatar';
import Preview from '../../modules/Preview';

const Avatars = () => {
  return (
    <Preview title="Avatar">
      <div className="cols">
        {/* Default */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Default</span>
            <div className="Avatar">
              <div className="Avatar-image">
                <img
                  width="60"
                  height="60"
                  src="https://avatars0.githubusercontent.com/u/14273133?s=460&u=4ad1869de7c1266f64d513e8d74f0da4e9c16a04&v=4"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Hover */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Hover</span>
            <div className="Avatar Avatar--hovered">
              <div className="Avatar-image">
                <img
                  width="60"
                  height="60"
                  src="https://avatars0.githubusercontent.com/u/14273133?s=460&u=4ad1869de7c1266f64d513e8d74f0da4e9c16a04&v=4"
                />
              </div>
            </div>
          </div>
        </div>
        {/* Selected */}
        <div className="col col-xs-4 col-sm-4 col-md-3 col-lg-2">
          <div className="display-flex flex-column flex-items-center">
            <span className="py-2">Selected</span>
            <div className="Avatar Avatar--selected">
              <div className="Avatar-image">
                <img
                  width="60"
                  height="60"
                  src="https://avatars0.githubusercontent.com/u/14273133?s=460&u=4ad1869de7c1266f64d513e8d74f0da4e9c16a04&v=4"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Preview>
  );
};

export default Avatars;
