import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import marginMd from './margin.md';

const MarginExample = ({ id }) => {
  return (
    <CodeExample codeSnippet={marginMd} id={id} jsx>
      <div className="display-flex flex-column border" style={{ width: 160 }}>
        <span className="m-2 border">.m-2</span>
        <span className="mt-2 border">.mt-2</span>
        <span className="mr-2 border">.mr-2</span>
        <span className="mb-2 border">.mb-2</span>
        <span className="ml-2 border">.ml-2</span>
        <span className="mx-2 border">.mx-2</span>
        <span className="my-2 border">.my-2</span>
        <span className="mx-auto border">.mx-auto</span>
      </div>
    </CodeExample>
  );
};

MarginExample.propTypes = {
  id: PropTypes.string
};

export default MarginExample;
