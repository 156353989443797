import React from 'react';
import ToastExample from './ToastExample';

const Toasts = () => (
  <div>
    <h1>Toast component</h1>
    <ToastExample />
  </div>
);
export default Toasts;
