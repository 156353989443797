import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import borderMd from './border.md';

const BorderExample = ({ id }) => {
  return (
    <CodeExample codeSnippet={borderMd} id={id} jsx>
      <div className="border-0">.border-0</div>
      <div className="border my-3 p-4">.border</div>
      <div className="border-top my-3 p-4">.border-top</div>
      <div className="border-right my-3 p-4">.border-right</div>
      <div className="border-bottom my-3 p-4">.border-bottom</div>
      <div className="border-left my-3 p-4">.border-left</div>
    </CodeExample>
  );
};

BorderExample.propTypes = {
  id: PropTypes.string
};

export default BorderExample;
