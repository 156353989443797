import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../../modules/CodeExample';
import Tabs from '../../../../modules/tabs/Tabs';
import TabList from '../../../../modules/tabs/TabList';
import TabPanels from '../../../../modules/tabs/TabPanels';
import Tab from '../../../../modules/tabs/Tab';
import TabPanel from '../../../../modules/tabs/TabPanel';
import TabsUncontrolledMd from './TabsUncontrolled.md';

const TabsUncontrolled = ({ id }) => {
  return (
    <CodeExample
      title="Tabs uncontrolled"
      description="The uncontrolled version of the Tab component."
      codeSnippet={TabsUncontrolledMd}
      id={id}
    >
      <Tabs className="width-full">
        <TabList className="">
          <Tab>APPROVAL REQUESTS</Tab>
          <Tab>UPDATE REQUESTS</Tab>
          <Tab>ALL USERS</Tab>
        </TabList>
        <TabList>
          <TabPanels className="p-4">
            <TabPanel>
              <h4>Approval requests content..</h4>
            </TabPanel>
            <TabPanel>
              <h4>Update requests content..</h4>
            </TabPanel>
            <TabPanel>
              <h4>All users content..</h4>
            </TabPanel>
          </TabPanels>
        </TabList>
      </Tabs>
    </CodeExample>
  );
};

TabsUncontrolled.propTypes = {
  id: PropTypes.string
};

export default TabsUncontrolled;
