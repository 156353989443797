import React from 'react';
import PropTypes from 'prop-types';
import Markdown from '../../../modules/Markdown.jsx';

const breakpoints = `
> Breakpoints are also supported (xs, sm, md, lg, xl).

| Name                        | Description                                  |
|-----------------------------|----------------------------------------------|
|\`.{breakpoint}:p-{scale}\`  | Sets padding to all sides.                   |
|\`.{breakpoint}:pt-{scale}\` | Sets padding-top.                            |
|\`.{breakpoint}:pr-{scale}\` | Sets padding-right.                          |
|\`.{breakpoint}:pb-{scale}\` | Sets padding-bottom.                         |
|\`.{breakpoint}:pl-{scale}\` | Sets padding-left.                           |
|\`.{breakpoint}:px-{scale}\` | Sets padding-right and padding-left.         |
|\`.{breakpoint}:py-{scale}\` | Sets padding-top and padding-bottom.         |
|\`.{breakpoint}:px-auto\`    | Sets padding-right and padding-left to auto. |
`;

const BREAKPOINTS = ({ id }) => {
  return <Markdown id={id}>{breakpoints}</Markdown>;
};

BREAKPOINTS.propTypes = {
  id: PropTypes.string
};

export default BREAKPOINTS;
