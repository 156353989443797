import React from 'react';
import Preview from '../../modules/Preview';

const Toast = () => {
  return (
    <Preview title="Toast" to="/toast">
      <div className="Toast">
        <div className="Toast--dismissButton" />
      </div>
    </Preview>
  );
};

export default Toast;
