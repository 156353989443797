import React from 'react';
import DisplayExample from './DisplayExample';
import API from './API';
import BREAKPOINTS from './BREAKPOINTS';

const Display = () => (
  <div>
    <h1>Display utility</h1>
    <API />
    <h1>Code examples</h1>
    <DisplayExample />
    <BREAKPOINTS />
  </div>
);
export default Display;
