import React from 'react';
import PropTypes from 'prop-types';
import CodeExample from '../../../modules/CodeExample.jsx';
import border2Md from './border2.md';

const Border2Example = ({ id }) => {
  return (
    <CodeExample codeSnippet={border2Md} id={id} jsx>
      <div className="border-2 my-3 p-4">.border-2</div>
      <div className="border-top-2 my-3 p-4">.border-top-2</div>
      <div className="border-right-2 my-3 p-4">.border-right-2</div>
      <div className="border-bottom-2 my-3 p-4">.border-bottom-2</div>
      <div className="border-left-2 my-3 p-4">.border-left-2</div>
    </CodeExample>
  );
};

Border2Example.propTypes = {
  id: PropTypes.string
};

export default Border2Example;
