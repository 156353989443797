import React from 'react';
import Preview from '../../modules/Preview';

const Link = () => {
  return (
    <Preview title="Tabs">
      <div className="Tabs">
        <div className="Tab-list">
          <button className="Tab Tab--active">
            <div className="Tab--container">Tab 1</div>
          </button>
          <button className="Tab">
            <div className="Tab--container">Tab 2</div>
          </button>
          <button className="Tab" disabled>
            <div className="Tab--container">Disabled tab</div>
          </button>
        </div>
      </div>
    </Preview>
  );
};

export default Link;
