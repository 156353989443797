import React from 'react';
import Preview from '../../modules/Preview';

const Checkboxs = () => (
  <Preview title="Checkboxes">
    <div className="cols mb-7">
      {/* Default */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Default</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="Checkbox">
              <input
                className="Checkbox-input"
                id="checkbox"
                type="checkbox"
                name="checkbox"
              />
              <label htmlFor="checkbox" className="Checkbox-label">
                Checkbox
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Checked */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Checked</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="Checkbox">
              <input
                className="Checkbox-input Checkbox-input--checked"
                type="checkbox"
                name="checkbox"
              />
              <label className="Checkbox-label">Checkbox</label>
            </div>
          </div>
        </div>
      </div>
      {/* Hovered */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Hover</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="Checkbox">
              <input
                className="Checkbox-input Checkbox-input--hovered"
                type="checkbox"
                name="checkbox"
                id="checkbox-hover"
              />
              <label htmlFor="checkbox-hover" className="Checkbox-label">
                Checkbox
              </label>
            </div>
          </div>
        </div>
      </div>
      {/* Disabled */}
      <div className="col col-xs-12 col-sm-12 col-md-3 col-lg-3">
        <div className="display-flex flex-column flex-sm-items-start flex-md-items-center">
          <span className="py-2">Disabled</span>
          <div className="display-flex flex-column width-full flex-sm-items-start flex-md-items-center">
            <div className="Checkbox">
              <input
                className="Checkbox-input Checkbox-input--disabled"
                type="checkbox"
                name="checkbox"
                disabled
              />
              <label className="Checkbox-label">Checkbox</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Preview>
);

export default Checkboxs;
