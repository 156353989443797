import React from 'react';
import ModalExample from './ModalExample';

const Modals = () => (
  <div>
    <h1>Modal component</h1>
    <ModalExample />
  </div>
);
export default Modals;
