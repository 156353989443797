import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

const TabPanel = ({ children, className }) => (
  <div className={classnames('Tab-panel', className)}>{children}</div>
);

TabPanel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default TabPanel;
