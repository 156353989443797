import React from 'react';
import Typography from './Typography';
import Logos from './Logos';
import Colours from './Colours';
import Intro from './Intro';
import Avatars from './Avatars';
import Buttons from './Buttons';
import TextFields from './TextFields';
import RadioButtons from './RadioButtons';
import CheckBoxes from './CheckBoxes';
import Legend from './Legend';
import Status from './Status';
import Toast from './Toast';
import Link from './Link';
import Slider from './Slider';
import Tabs from './Tabs';

const Home = () => {
  return (
    <div className="px-3">
      <Intro />
      <Typography />
      <Logos />
      <Colours />
      <Avatars />
      <Buttons />
      <TextFields />
      <RadioButtons />
      <CheckBoxes />
      <Legend />
      <Slider />
      <Status />
      <Toast />
      <Link />
      <Tabs />
    </div>
  );
};

export default Home;
